@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');

.app-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #d7d8db; /* Off-white background color */
  padding: 0 30px; /* Padding for the container */
  overflow: hidden; /* Prevent horizontal overflow */
}

.heading {
  font-size: 48px;
  color: red; /* Loving pink color */
  font-family: 'Caveat', cursive;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.scrollable-container {
  flex: 1; /* Allow the scrollable content to grow and shrink */
  width: 100%;
  max-height: calc(100vh - 100px); /* Adjust max height for proper scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 20px;
  box-sizing: border-box;
}

.image-heart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.picture {
  width: 250px; /* Increased size */
  height: 250px; /* Increased size */
  border-radius: 50%;
  margin-right: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.heart {
  position: relative;
  width: 150px; /* Increased size */
  height: 150px; /* Increased size */
  background: linear-gradient(135deg, red, #ff4b2b);
  transform: rotate(-45deg);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.heart:before,
.heart:after {
  content: "";
  position: absolute;
  width: 150px; /* Increased size */
  height: 150px; /* Increased size */
  background: linear-gradient(135deg, red, #ff4b2b);
  border-radius: 50%;
}

.heart:before {
  top: -90px; /* Adjusted position */
  left: 0;
}

.heart:after {
  left: 90px; /* Adjusted position */
  top: 0;
}

.text-container {
  margin-top: 20px;
  text-align: center;
}

.sorry-text {
  font-size: 30px;
  color: red; /* Loving pink color */
  font-family: 'Caveat', cursive;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  background: #bf82ec;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.side-heart {
  position: absolute;
  width: 100px;
  height: 100px;
  background: linear-gradient(135deg, #ff416c, #ff4b2b);
  transform: rotate(-45deg);
}

.side-heart:before,
.side-heart:after {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  background: linear-gradient(135deg, #ff416c, #ff4b2b);
  border-radius: 50%;
}

.side-heart:before {
  top: -50px;
  left: 0;
}

.side-heart:after {
  left: 50px;
  top: 0;
}

.left-heart {
  left: 100px;
  top: 90%;
  transform: translateY(-50%) rotate(-45deg);
}

.right-heart {
  right: 100px;
  top: 90%;
  transform: translateY(-50%) rotate(-45deg);
}

.image-container {
    margin-top:5px;
    text-align: center;
  }
  
  .anniversary-image {
    width: 250px; /* Adjust width as needed */
    height: 300px; /* Maintain aspect ratio */
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: 2px solid #ff6f61; /* Optional border */
  }