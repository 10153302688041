@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');

.app-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5abef; /* Off-white background color */
  padding: 0 30px; /* Added padding to the container */
  overflow: hidden; /* Hide overflow to ensure hearts stay within bounds */
}

.heading {
  font-size: 48px;
  color: #d918c9; /* Loving pink color */
  font-family: 'Caveat', cursive;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.image-heart-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.picture {
  width: 250px; /* Increased size */
  height: 250px; /* Increased size */
  border-radius: 50%;
  margin-right: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.heart {
  position: relative;
  width: 150px; /* Increased size */
  height: 150px; /* Increased size */
  background: linear-gradient(135deg, #ff416c, #ff4b2b);
  transform: rotate(-45deg);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.heart:before,
.heart:after {
  content: "";
  position: absolute;
  width: 150px; /* Increased size */
  height: 150px; /* Increased size */
  background: linear-gradient(135deg, #ff416c, #ff4b2b);
  border-radius: 50%;
}

.heart:before {
  top: -75px; /* Adjusted position */
  left: 0;
}

.heart:after {
  left: 75px; /* Adjusted position */
  top: 0;
}

.text-container {
  margin-top: 20px;
  text-align: center;
}

.sorry-text {
  font-size: 30px;
  color: #fa02e6; /* Loving pink color */
  font-family: 'Caveat', cursive;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  background: #f5abef;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.side-heart {
  position: absolute;
  width: 100px;
  height: 100px;
  background: linear-gradient(135deg, #ff416c, #ff4b2b);
  transform: rotate(-45deg);
}

.side-heart:before,
.side-heart:after {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  background: linear-gradient(135deg, #ff416c, #ff4b2b);
  border-radius: 50%;
}

.side-heart:before {
  top: -50px;
  left: 0;
}

.side-heart:after {
  left: 50px;
  top: 0;
}

.left-heart {
  left: 100px;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

.right-heart {
  right: 100px;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}
