/* src/Balloon.css */
.balloon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
  }
  
  .balloon {
    width: 100px;
    height: 140px;
    background-color: #f573ea;
    border-radius: 50% 50% 50% 50%;
    position: relative;
  }
  
  .knot {
    width: 10px;
    height: 10px;
    background-color: #f573ea;
    position: relative;
    top: -6px;
    border-radius: 50%;
  }
  
  .string {
    width: 2px;
    height: 100px;
    background-color: #000;
    position: relative;
    top: -8px;
  }
  