.falling {
    position: fixed;
    top: -10px;
    width: 20px;
    height: 20px;
    opacity: 0.8;
    animation: fall linear infinite;
    z-index: 9999;
    pointer-events: none;
  }
  
  /* Rose Petals */
  .petal {
    background: radial-gradient(circle, #ff69b4, #ff1493);
    border-radius: 50%;
  }
  
  /* Hearts */
  .heart {
    width: 30px;
    height: 30px;
    background: linear-gradient(135deg, red, #ff4b2b);
    transform: rotate(-45deg);
    animation-duration: 2s; /* Slightly faster for hearts */
  }
  
  .heart:before,
  .heart:after {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    background: linear-gradient(135deg, red, #ff4b2b);
    border-radius: 50%;
  }
  
  .heart:before {
    top: -15px;
    left: 0;
  }
  
  .heart:after {
    left: 15px;
    top: 0;
  }
  
  /* Falling Animation */
  @keyframes fall {
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 0.8;
    }
    50% {
      transform: translateY(50vh) rotate(180deg);
      opacity: 0.6;
    }
    100% {
      transform: translateY(100vh) rotate(360deg);
      opacity: 0;
    }
  }
  